<template>
  <b-card title="DrillDown Report">
    

    <!-- table -->
        <vue-good-table
          ref="drilldown-report"
          mode="remote"
          :columns="columns"
          :rows="rows"
          :rtl="direction"
          :isLoading="isLoadingProp" 
          :search-options="{
            enabled: true,
            externalQuery: searchTerm
          }"
          :totalRows="totalRecords"
          @on-column-filter="onColumnFilter"
          :pagination-options="{
            enabled: true,
          }"
          @on-sort-change="onSortChange"
        >
        <div slot="loadingContent">
          <b-spinner
            ref="spinner_one"
            variant="primary"
            class="mr-1"
            type="grow"
            style="width: 4rem; height: 4rem;"
          />
        </div>
        <div slot="table-actions">
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            v-on:click="greet(allData)"
            >
            Download CSV
        </b-button>
        
        <!-- <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            v-on:click="reconciled"
            >
            Mark as Reconciled
        </b-button> -->
      </div>
          <template
            slot="table-row"
            slot-scope="props"
          >

            <!-- Column: Name -->
            <!-- <span
              v-if="props.column.field === 'fullNamae'"
              class="text-nowrap"
            >
              <b-avatar
                :src="props.row.avatar"
                class="mx-1"
              />
              <span class="text-nowrap">{{ props.row.fullName }}</span>
            </span> -->

            <!-- Column: Status -->
            <span v-if="props.column.field === 'shipment_status'">
              <b-badge :variant="statusVariant(props.row.shipment_status)">
                {{ props.row.shipment_status }}
              </b-badge>
            </span>

            <!-- Column: Action -->
            <span v-else-if="props.column.field == 'paid_by_courier'">
              <span v-if="props.row.paid_by_courier == 'Yes'">
                <b-badge variant="light-success">
                  {{ props.row.paid_by_courier }}
                </b-badge>
              </span>
              <span v-else>
                <b-badge variant="light-danger">
                  {{ props.row.paid_by_courier }}
                </b-badge>
              </span>
            </span>
            <span v-else-if="props.column.field == 'bank_reconciliation'">
              <span v-if="props.row.paid_by_courier == 'Yes'">
                <b-badge variant="light-success">
                  {{ props.row.paid_by_courier }}
                </b-badge>
              </span>
              <span v-else>
                <b-badge variant="light-danger">
                  {{ props.row.paid_by_courier }}
                </b-badge>
              </span>
            </span>
            

            <!-- Column: Action -->
            <!-- <span v-else-if="props.column.field === 'action'">
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item>
                    <feather-icon
                      icon="Edit2Icon"
                      class="mr-50"
                    />
                    <span>Edit</span>
                  </b-dropdown-item>
                  <b-dropdown-item>
                    <feather-icon
                      icon="TrashIcon"
                      class="mr-50"
                    />
                    <span>Delete</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </span> -->

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">

              <!-- page length -->
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="pages"
                  class="mx-1"
                  @input="handlePageChange"
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>

              <!-- pagination -->
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @change="handleChangePage"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>

   

    
    
  </b-card>
</template>
<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
<script type="text/javascript" src="libs/FileSaver/FileSaver.min.js"></script>
<script type="text/javascript" src="libs/js-xlsx/xlsx.core.min.js"></script>
<script type="text/javascript" src="tableExport.min.js"></script>
<script>
import {
  BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdownItem, BDropdown,BCard,BButton,BTabs,BTab,BSpinner,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import Prism from 'vue-prism-component'
import store from '@/store/index'
import pages from '@/router/routes/pages'
import Ripple from 'vue-ripple-directive'
import reportStore from '@/store'

export default {
  components: {
    VueGoodTable,
    BCard,
    BSpinner,
    BTab,
    BTabs,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    Prism,
    BDropdownItem,
    BDropdown,
    BButton,

  },
  directives: {
    Ripple,
  },
  data() {
    return {
      tabIndex: 0,
      mainTopic:null,
      subtopic:null,
      courierTopic:this.$route.params.invoice_id,
      allData:null,
      log: [],
      isLoadingProp:true,
      pageLength: 10,
      totalRecords:0,
      dir: false,
      pages: ['10','20','50','100'],
      columns: [
        // {
        //   label: 'Name',
        //   field: 'fullName',
        //   filterOptions: {
        //     styleClass: 'class1', // class to be added to the parent th element
        //     enabled: true, // enable filter for this column
        //     placeholder: 'Filter This Thing', // placeholder for filter input
        //     filterValue: '', // initial populated value for this filter
        //     filterDropdownItems: [], // dropdown (with selected values) instead of text input
        //     filterFn: this.columnFilterFn, //custom filter function that
        //     trigger: 'enter', //only trigger on enter not on keyup 
        //   },
        // },
        {
          label: 'Seller ID',
          field: 'seller_id',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'Seller Name',
          field: 'seller_name',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'Order ID',
          field: 'marketplace_reference_id',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'CN #',
          field: 'tracking_number',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'Courier',
          field: 'courier_name',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'Courier Service',
          field: 'courier_service',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'Status',
          field: 'shipment_status',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'Shipment Booking Date',
          field: 'booking_date',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'Terminal Date',
          field: 'terminal_date',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'COD Amount',
          field: 'cod',
          formatFn:this.formatCharges,
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'Unity Weight',
          field: 'unity_weight',
          formatFn:this.formatCharges,
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'Actual Weight',
          field: 'actual_weight',
          formatFn:this.formatCharges,
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'Unity Weight Charges',
          field: 'unity_weight_charges',
          formatFn:this.formatCharges,
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'Actual Weight Charges',
          field: 'actual_weight_charges',
          formatFn:this.formatCharges,
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'Unity Cash Handling',
          field: 'unity_cash_handling_charges',
          formatFn:this.formatCharges,
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'Actual Cash Handling',
          field: 'actual_cash_handling_charges',
          formatFn:this.formatCharges,
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'Unity Fuel Surcharge',
          field: 'unity_fuel_surcharge',
          formatFn:this.formatCharges,
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'Actual Fuel Surcharge',
          field: 'actual_fuel_surcharge',
          formatFn:this.formatCharges,
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'Unity Sales Tax',
          field: 'unity_sale_tax_for_courier',
          formatFn:this.formatCharges,
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'Actual Sales Tax',
          field: 'actual_sale_tax_for_courier',
          formatFn:this.formatCharges,
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'Unity Total Courier Charges with Tax',
          field: 'unity_total_courier_charges_with_tax',
          formatFn:this.formatCharges,
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'Actual Total Courier Charges with Tax',
          field: 'actual_total_courier_charges_with_tax',
          formatFn:this.formatCharges,
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'Unity Booking Charges',
          field: 'unity_booking_charges',
          formatFn:this.formatCharges,
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'SST On Unity',
          field: 'unity_sst',
          formatFn:this.formatCharges,
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'Total Payment From Seller',
          field: 'payment_from_seller',
          formatFn:this.formatCharges,
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'Paid by Courier',
          field: 'paid_by_courier',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'Courier Payment Date',
          field: 'courier_payment_date',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'Bank Reconciliation',
          field: 'bank_reconciliation',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'Bank Reconciliation Date',
          field: 'bank_reconciliation_date',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
      ],
      rows: [],
      serverParams: {
        // a map of column filters example: {name: 'john', age: '20'}
        columnFilters: {
        },
        sort: {
          field: '', // example: 'name'
          type: '', // 'asc' or 'desc'
        },
        page: 1, // what page I want to show
        perPage: 10, // how many items I'm showing per page
      },
      searchTerm: '',
      shipment_status: [{
        1: 'Lost', 2: 'Delivered', 3: 'Return', 4: 'Losst', 5: 'Lost',
      },
      {
        1: 'light-primary', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
      }],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Lost      : 'light-primary',
        Delivered : 'light-success',
        Return     : 'light-danger',
        Losts    : 'light-warning',
        Losst      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return shipment_status => statusColor[shipment_status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    
   this.isLoadingProp = true;
    this.mainTopic = reportStore.state.report.mainTopic;
    this.subTopic = reportStore.state.report.subTopic;
    this.courierTopic = reportStore.state.report.courierTopic;
    this.sellerTopic = reportStore.state.report.sellerTopic;
   
    this.$http.post('drilldown-report',{
        data:this.serverParams,
        mainTopic:this.mainTopic,
        subTopic:this.subTopic,
        courierTopic:this.courierTopic,
        sellerTopic:this.sellerTopic
    })
    .then(res => { this.rows = res.data.data;
        this.totalRecords = res.data.totalRecords;
        this.updateAllData(res.data.data);
        this.isLoadingProp = false;
    })

    
    
  },
//   beforeDestroy() {
//     reportStore.commit('report/SET_MAIN_TOPIC',null);
//     reportStore.commit('report/SET_SUB_TOPIC',null);
//     reportStore.commit('report/SET_COURIER_TOPIC',null);
//   },
  
  methods: {
    formatCharges:function(data){
      if(data != null){
        return data.toFixed(2);
      }
    },
    updateAllData(data){
      this.allData = null;
      this.allData = data;
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    // handleSearch(searching) {
    //   this.log.push(`The user searched for: ${searching}`)
    // },
    handleChangePage(page) {
      this.updateParams({page: page});
      this.loadItems();
    },
    handlePageChange(active) {
      this.updateParams({perPage: active});
      this.loadItems();
    },
    onSortChange(params) {
      this.updateParams({
        sort: params,
      });
      this.loadItems();
    },
    onColumnFilter(params) {
        this.updateParams(params);
        this.loadItems();
    },
    
    loadItems() {
      this.isLoadingProp = true;
        this.$http.post('drilldown-report',{
            data:this.serverParams,
            mainTopic:this.mainTopic,
            subTopic:this.subTopic,
            courierTopic:this.courierTopic,
            sellerTopic:this.sellerTopic
        }).then(response => {
          //  this.totalRecords = response.totalRecords;
          this.totalRecords = response.data.totalRecords;
          this.rows = response.data.data;
          this.updateAllData(response.data.data);
          this.isLoadingProp = false;
        });

    },

    greet: function (arrData) {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.keys(arrData[0]).join(";"),
        ...arrData.map(item => Object.values(item).join(";"))
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "export.csv");
      link.click();
      // $('.vgt-table bordered').tableExport({type:'csv'});
    }
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>